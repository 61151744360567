import modes from "@/constants/modes.json";
import brands from "@/constants/brands.json";

export default {

  data() {
    return {
      modes,
      brands
    }
  },

  created() {
  },

  methods: {

    roundNumberV1(num, scale) {

      if (!("" + num).includes("e")) {
        return +(Math.round(num + "e+" + scale) + "e-" + scale);
      } else {
        var arr = ("" + num).split("e");
        var sig = ""
        if (+arr[1] + scale > 0) {
          sig = "+";
        }
        var i = +arr[0] + "e" + sig + (+arr[1] + scale);
        var j = Math.round(i);
        var k = +(j + "e-" + scale);
        return k;
      }
    },

    getBrandLogo(pointProps_) {
      const physical_mode = this.brands.filter(item => (item.type === pointProps_.physical_mode && (item.name === pointProps_.name || item.name === pointProps_.slug)));
      if (physical_mode.length !== 0) {
        return require(`@/assets/pictures/brands/${physical_mode[0].logo}`);
      } else {
        return null;
      }
    },

    aroundDistance(distance_) {
      if (distance_ < 1000) {
        return this.$t('view.point-details.around-distance-meters', {distance: distance_});
      }
      return this.$t('view.point-details.around-distance-km', {distance: Math.round(distance_/1000)});
    },

    aroundDistance2(distance_) {
      if (distance_ < 1000) {
        return `${parseInt(distance_) }m`;
      }
      return `${Math.round(distance_/1000)}km`;
    },

    formatDistance(coordinates) {
      const currentMarkerCoords = this.$store.getters["modes/getCurrentMarker"];
      if (currentMarkerCoords) {
        const distance = this.distanceBetweenCoordinates(currentMarkerCoords.lat, currentMarkerCoords.lng, coordinates.lat, coordinates.lng);

        // < 1000 meters
        if (parseInt(distance) === 0) {
          return `${parseInt(distance*1000)}m`
        }
        return `${this.roundNumberV1(distance, 2)}km`
      }
      return "";

    },

    distanceBetweenCoordinates(lat1, lon1, lat2, lon2) {

      var rad = (x) => {return x*Math.PI/180;}

      var R     = 6378.137;                     //La ratio de la Terre en km (WGS84)
      var dLat  = rad( lat2 - lat1 );
      var dLong = rad( lon2 - lon1 );

      var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(rad(lat1)) * Math.cos(rad(lat2)) * Math.sin(dLong/2) * Math.sin(dLong/2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      var d = R * c;

      return d.toFixed(4)
    },

  },

  computed: {
  },
}