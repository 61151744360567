<template>
  <v-container style="max-height: 90vh; overflow-y: auto;">

    <v-row no-gutters justify="center">
      <v-col cols="12">
        <v-row justify="start" no-gutters>
          <v-col cols="1">
            <v-btn
              @click="getStops"
              icon
              color="primary"
              style="width: 30px !important; margin-top: -7px !important;">
              <v-icon small>fas fa-redo</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="10">
            <h3 class="font-weight-bold text-center">Les véhicules les plus proches</h3>
          </v-col>
          <v-spacer />
        </v-row>
      </v-col>

      <!-- Modes -->
      <v-col cols="12" class="my-12" v-if="isLoadedModes">
        <v-row justify="center">
          <template v-for="mode_ of extractedModes">
            <v-btn icon class="mx-3">
              <IconModePointDetails
                @toggle="toggleMode(mode_.name)"
                :mode="mode_"
                :enabled="currentModes.includes(mode_.name)"
              />
            </v-btn>
          </template>
        </v-row>
      </v-col>

      <!-- Stops -->
      <v-col cols="12" class="mt-6">
        <v-card style="border-radius: 15px !important;">
          <v-list
            v-if="isLoadedStops && extractedStops.length > 0"
            :style="`max-height: ${isStopsListExpanded ? 800 : 400}px !important;overflow-y: hidden !important;`">
            <template v-for="stop_ of extractedStops">
              <v-list-item>
                <v-row no-gutters align="center" justify="space-between">

                  <!-- Icon -->
                  <v-col cols="2">
                    <v-img
                      v-if="getBrandLogo(stop_.properties)"
                      :src="getBrandLogo(stop_.properties)"
                      width="20"
                      style="margin: 0 auto !important;"/>
                  </v-col>

                  <!-- Slug -->
                  <v-col cols="3">
                    {{stop_.properties.slug.toUpperCase()}}
                  </v-col>

                  <!-- Battery or available vehicles -->
                  <v-col cols="3">
                    <div v-if="stop_.properties.physical_mode === 'BIKE_STATION'">
                      <div
                        v-if="stop_.properties.availableVehicles && stop_.properties.availableVehicles !== 'null' &&
                              stop_.properties.availableStands && stop_.properties.availableStands !== 'null'">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on">
                            <v-icon small color="primary">far fa-bicycle</v-icon>
                            <span style="margin-left: 3px !important;">{{stop_.properties.availableVehicles}}</span>
                            <v-icon small color="primary" class="ml-2">far fa-map-marker-smile</v-icon>
                            <span style="margin-left: 3px !important;">{{stop_.properties.availableStands}}</span>
                          </span>
                          </template>
                          <div>
                            <span>Vélos disponibles : {{stop_.properties.availableVehicles}}</span>
                            <br />
                            <span>Places disponibles : {{stop_.properties.availableStands}} </span>
                          </div>
                        </v-tooltip>


                      </div>
                    </div>
                    <div v-else>
                      <div v-if="stop_.properties.battery && stop_.properties.battery !== 'null'">
                        <v-icon size="16" color="error" v-if="stop_.properties.battery < 10">fas fa-battery-quarter</v-icon>
                        <v-icon size="16" color="warning" v-else-if="stop_.properties.battery < 30">fas fa-battery-half</v-icon>
                        <!--v-icon size="16" color="secondary" v-else-if="stop_.properties.battery < 80">fas fa-battery-three-quarters</v-icon-->
                        <v-icon size="16" color="success" v-else>fas fa-battery-full</v-icon>
                        <span class="ml-1">{{stop_.properties.battery}}%</span>
                      </div>
                    </div>
                  </v-col>

                  <!-- Distance -->
                  <v-col cols="3" class="text-left">
                    <span v-if="parseInt(stop_.properties.distance) > 0">{{aroundDistance2(stop_.properties.distance)}}</span>
                    <span v-else style="font-size: 12px !important;" class="font-weight-bold">A proximité</span>
                  </v-col>

                  <!-- Go icon -->
                  <v-col cols="1">
                    <v-btn icon elevation="2" class="mr-1" @click="goToItinerary({lat: stop_.properties.lat, lng: stop_.properties.lng, name: stop_.properties.name})">
                      <img src="@/assets/icons/controls/go_btn.svg" width="20" class="ml-1"/>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item>
            </template>
          </v-list>
          <Skeleton v-else-if="!isLoadedStops" type="list-item" :occurrence="3" />
          <v-list-item v-else class="text-center">{{$t('view.point-details.micro-no-result')}}</v-list-item>
          <div
            v-if="isLoadedStops && extractedStops.length > 0"
            class="text-center ma-0 pa-0">
            <v-btn icon @click="isStopsListExpanded = !isStopsListExpanded">
              <v-icon v-if="isStopsListExpanded" color="primary">far fa-chevron-up</v-icon>
              <v-icon v-else color="primary">far fa-chevron-down</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import pointDetails from "@/mixins/pointDetails";
import qs from "qs";

export default {
  name: "PointDetailsMicroMobility",

  data() {
    return {
      stops: [],
      isLoadedModes: false,
      isLoadedStops: false,
      isStopsListExpanded: false,
      extractedModes: [],
      currentModes: [],
      extractedStops: []
    }
  },

  components: {
    IconModePointDetails: () => import("@/components/Common/IconModePointDetails"),
    Skeleton: () => import("@/components/Common/Skeleton"),
  },

  mixins: [pointDetails],

  mounted() {

    const a = this.modes.filter((el) => el.category === 'transports_alt').reverse()
    this.extractedModes = [...a];
    console.log(this.extractedModes);
    if (!!this.$route.query.mode) {
      const a = this.modes.find((el) => el.name === this.$route.query.mode.toUpperCase());
      if (a)
        this.currentModes = [a.name];
    }
    else
      this.currentModes = this.extractedModes.map((el) => el.name);

    this.isLoadedModes = true;
    this.getStops();
  },

  methods: {
    getStops() {

      this.isLoadedStops = false;
      this.$http
        .get(`/map/viewer/modes?coords=${this.coords.lat},${this.coords.lng}`, {
          params: {
            modes: this.extractedModes.map((el) => el.name)
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, {arrayFormat: "brackets"})
          },
          headers: this.$session.get('jwt') ? {Authorization: "Bearer " + this.$session.get('jwt')} : {}
        })
        .then((res) => {
          if (res.data && res.data.length > 0)
            this.stops = res.data;
          this.extractStops();
        })
        .catch(err => {
          //this.$store.commit("alert/showError", this.$t('view'));
        })
        .finally(() => {
          this.isLoadedStops = true;
        })
    },

    toggleMode(mode_) {
      this.currentModes = [mode_];
      this.extractStops();
    },

    extractStops() {
      if (this.currentModes.length > 0) {
        // init
        this.extractedStops = [];

        for (const stop of this.stops) {
          if (this.currentModes.includes(stop.properties.physical_mode)) {
            this.extractedStops.push(stop);
          }
        }
        console.log(this.extractedStops[0]);

        if (this.extractedStops.length > 0) {
          this.$store.commit("setCoordinates", {lng: this.extractedStops[0].geometry.coordinates[0], lat: this.extractedStops[0].geometry.coordinates[1]});
        }

      }
    },

    goToItinerary({lat, lng, name}) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.$router.push({
          name: 'Itinerary',
          query: {
            to: `${lat},${lng}`,
            toLabel: name,
            fromLabel: "Ma position",
            from: `${position.coords.latitude},${position.coords.longitude}`
          }
        });
      }, (error) => {
        this.$router.push({
          name: 'Itinerary',
          query: {
            to: `${lat},${lng}`,
            toLabel: name,
          }
        });
      });
    }
  },

  computed: {
    coords() {
      return this.$store.getters["coords"];
    },
  },

  watch: {
    "$route.query.mode"(newValue, oldValue) {
      this.toggleMode(newValue);
      this.getStops();
    },

    "$route.query.id"(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getStops();
      }
    }
  }
}
</script>

<style scoped>

</style>